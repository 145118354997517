<template>
    <div>
        <top title="使用说明" style="font-weight:bold;"/>
        <div class="box">
        <p>一、如何使用付款&nbsp;</p>

<p>当你登录惠医保公众号后，点击首页的【线下直付】，向商家展示条形码及二维码，商家使用扫码枪或者摄像头等设备，快速扫描用户的条形码（或二维码）即可完成交易。&nbsp;</p>

<p>&nbsp;</p>

<p>二、付款额度&nbsp;</p>

<p>在安全系统保护下，单笔付款金额小于等于1000元（在境外商户消费时,&nbsp;单笔付款金额小于等于5000元）的交易，无需验证直付密码或其他交易指令验证方式（如指纹、刷脸等）。同时为了保障你的直付顺畅和安全，惠医保会根据客观情况和风险控制需要调整你的免密交易额度。&nbsp;</p>

<p>&nbsp;</p>

<p>三、扣款规则&nbsp;</p>

<p>1.优先按照你在付款码下方选择的直付方式进行扣款。</p>

<p>2.当你指定选择的直付方式扣款失败时：惠医保会将自动选择你已有的直付方式进行扣款尝试。</p>

<p>3.当通过上述方式依然扣款失败时，你将通过系统唤起的收银台页面手动选择直付方式。&nbsp;</p>

<p>&nbsp;</p>

<p>四、付款安全&nbsp;</p>

<p>1.你每次使用付款码时，需要网络才可以进行直付；</p>

<p>2.付款码仅用于线下当面直付时使用，请勿透过其他途径泄漏付款码页面的任何信息。&nbsp;</p>

<p>3.条形码每5分钟会提示刷新，并会在生成之后5分钟之内有效。&nbsp;</p>
</div>
    </div>
</template>

<script>
export default {

}
</script>
y
<style lang="scss" scoped>
 .van-nav-bar__title{
    font-weight: 700;
}
.box{
    padding: 0 vw(28) vw(24);
    line-height: vw(44);
    p{
        line-height: vw(44);
    }
}
</style>